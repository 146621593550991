import React, { useState } from 'react'
import Form from '../components/Form'
import Footer from '../components/Footer'
import Title from '../components/UI/Title'
import { FiGift } from 'react-icons/fi'
import Button from '../components/UI/Button'
import I18n from '../components/UI/I18n'
import Photo from '../components/UI/Photo'
import Menu from '../components/UI/Menu'
import Info from '../components/UI/Info'
import Carrousel from '../components/UI/Carrousel'
import HelpCenter from '../components/HelpCenter'
if (typeof window !== `undefined`) import('smoothscroll-polyfill').then(e => e.polyfill())
const [instagram, facebook, youtube] = ['https://www.instagram.com/_smileybox/', 'https://www.facebook.com/smileybox.bornephoto', 'https://www.youtube.com/channel/UCrO4rwo8HASvO-HHJMgh6Cg']

export default function Home() {
  if (typeof window !== `undefined`) window.location.href = /^en\b/.test(navigator.language) ? 'https://www.wizito.com/en/wedding-booth' : 'https://www.wizito.com/photobooth-mariage'
  return null
  /*const [showHelp, setShowHelp] = useState(false)
  const scrollForm = () => {
    window.scrollTo(0, document.getElementById('form').offsetTop)
  }
  return ( 
    <>
      <Menu showHelp={showHelp} setShowHelp={setShowHelp} instagram={instagram} facebook={facebook} youtube={youtube}>
        <div className="leading-tight lg:text-right">Réserver votre <span className="hidden lg:inline">borne photo</span> Smiley Box pour 329&nbsp;€
        <small className="font-thin block">Offre limitée dans le temps</small></div>
        <Button className="mt-2 lg:mt-0 lg:ml-4" variant="white" onClick={scrollForm}>Vérifier les disponibilités</Button>
      </Menu>
      <div className="mt-4 lg:mt-20">
        <div className="max-w-screen-lg mx-auto px-4 flex justify-center flex-col lg:flex-row items-center lg:mb-8">
          <div className="lg:mr-16 flex-shrink-0 w-full lg:w-1/2 px-12 lg:p-4 lg:-mt-32">
            <Photo index={0} />
          </div>
          <div className="mt-4">
            <img src="/logo.svg" className="hidden lg:block h-24 mb-4" />
            <Title className="lg:text-left">
              <I18n>landing.title</I18n>
            </Title>
            <div className="leading-relaxed text-base lg:text-lg mt-8 text-gray-600">
              <li>Montage simple en 5min</li>
              <li>Livraison offerte à domicile en Belgique et Luxembourg</li>
              <li>600 tirages inclus</li>
              <li>Garantie satisfaction à 100%</li>
            </div>
            <Button className="mt-10 mx-auto lg:mx-0" onClick={scrollForm}>Vérifier les disponibilités</Button>
            <div className="mt-16 lg:mt-24 transform scale-75 lg:scale-100">
              <div className="flex flex-col justify-center items-center bg-orange text-white w-20 h-20 p-4 pr-8 text-center relative">
                <FiGift className="absolute bottom-0 left-0 ml-3 mb-12 w-24 h-24 stroke-1 text-black" style={{ strokeWidth: '0.7' }} />
                <div className="text-5xl absolute bottom-0 left-0 ml-6 -mb-4 text-black font-medium w-32 whitespace-no-wrap">
                  <I18n>landing.launchPrice</I18n>
                </div>
                <div className="text-5xl absolute bottom-0 left-0 ml-40 -mb-4 text-black font-thin w-40 opacity-25 whitespace-no-wrap">
                  <div className="absolute w-full bg-black mt-8 opacity-50" style={{ transform: 'rotate(-12deg)', height: 3 }} />
                  <I18n>landing.basePrice</I18n>
                </div>
              </div>
              <div className="text-black text-sm mt-2">
                <I18n>landing.launchOffer</I18n>
              </div>
            </div>
          </div>
        </div>
        <div className="w-screen bg-blue p-10 my-4 text-white shadow">
          <div className="flex flex-col lg:flex-row justify-center items-center max-w-screen-lg mx-auto">
            <div className="w-full lg:w-1/2 flex-shrink-0 leading-tight lg:leading-snug text-base lg:text-lg">
              <Title white>Réservez dès à présent <br /> <div className="inline font-thin">votre photobooth Smiley Box</div></Title>
              <p className="mb-3">Concept original, ludique, ultra facile d’utilisation… <strong>La Smiley Box, c’est la borne photo à louer pour tous vos événements !</strong></p>
              <p className="mb-3">Livrée partout en Belgique et au Luxembourg à domicile, <strong>5 minutes de montage et c’est parti !</strong></p>
              <p className="mb-3">Vos invités imprimeront en quelques secondes leurs photos de qualité professionnelle et <strong>repartiront avec des souvenirs qui donneront le smile !</strong></p>
              <p className="mb-3">La Smiley Box vous apportera un max de fun pendant le grand jour (et même après !)</p>
              <Button className="my-4 mx-auto lg:mx-0" onClick={scrollForm}>Vérifier les disponibilités</Button>
            </div>
            <div>
              <Photo index={1} className="mt-4 -mb-16 lg:m-8" />
            </div>
          </div>
        </div>
        <div className="w-full pt-5 pb-10">
          <Title className="text-center">Notre offre comprend</Title>
          <Carrousel className="items-stretch">
            {[
              { description: '<strong>Imprimante à sublimation thermique</strong> facile à utiliser pour des impressions de qualité labo', image: '/icons/printer.png' },
              { description: 'Votre borne est <strong>livrée gratuitement à domicile</strong>', image: '/icons/delivery.png' },
              { description: 'Photobooth avec <strong>appareil photo reflex numérique</strong> ', image: '/icons/camera.png' },
              { description: '<strong>600 impressions</strong>  photos comprises au format 7,5x10 cm', image: '/icons/photos.png' },
              { description: 'Profitez de <strong>5 jours de location</strong de votre borne photo', image: '/icons/calendar.png' },
              // { description: '<strong>Flash professionnel</strong> pour un éclairage optimal', image: '/icons/flash.png' },
              { description: '<strong>Personnalisez le cadre</strong> de votre photo imprimée', image: '/icons/brush.png' },
              { description: 'Photos mises à disposition dans une <strong>galerie en ligne</strong>', image: '/icons/computer.png' },
              { description: 'Photobooth et logiciel entièrement <strong>créés et développés par nos soins</strong>', image: '/icons/france.png' },
            ].map(i => (
              <div key={i.title} className="flex flex-col justify-center select-none items-center w-40 lg:w-56 m-2 lg:m-4 text-center bg-white px-2 py-4 lg:px-4 rounded shadow leading-tight lg:leading-normal">
                <div className="relative h-20 w-20 mb-2 mt-1">
                  <img src={i.image} className="relative flex justify-center items-center select-none h-20 z-10 pointer-events-none" />
                  <div className="bg-orange w-12 h-12 absolute bottom-0 left-0 -ml-6 z-0" />
                </div>
                <p className="text-sm lg:text-base leading-tight mt-2 text-gray-600" dangerouslySetInnerHTML={{ __html: i.description }} />
              </div>
            ))}
          </Carrousel>
          <Title className="mt-4 text-center">Les options disponibles</Title>
          <Carrousel className="items-stretch">
            {[
              { description: 'Assurance annulation', image: '/icons/assurance-annulation.png' },
              { description: 'Assurance dégradation', image: '/icons/assurance-degradation.png' },
              // { description: 'Livraison à domicile', image: '/icons/livraison-domicile.png' },
              { description: 'Consommable supplémentaire', image: '/icons/conso.png' }
            ].map(i => (
              <div key={i.title} className="relative flex flex-col justify-center items-center select-none w-40 lg:w-56 m-2 lg:m-4 text-center bg-white px-2 py-4 lg:px-4 rounded shadow leading-tight lg:leading-normal">
                {i.free &&
                  <div className="absolute top-0 -mt-4 text-sm bg-orange text-white px-3 py-1 w-full text-center rounded-t shadow">OFFERT</div>
                }
                <div className="relative h-20 w-20 mb-2 mt-1">
                  <img src={i.image} className="relative flex justify-center items-center h-20 z-10 pointer-events-none" />
                </div>
                <p className="text-sm lg:text-base leading-tight mt-2 text-gray-600" dangerouslySetInnerHTML={{ __html: i.description }} />
              </div>
            ))}
          </Carrousel>
        </div>
        <div className="relative w-screen mb-12 pt-6 text-white">
          <div className="absolute top-0 bg-blue w-full h-full max-h-3/4 lg:max-h-4/5 shadow" />
          <Title className="text-center" white>Et en vrai, elle ressemble à quoi ?</Title>
          <Carrousel className="">
            <Photo index={2} className="h-48 m-2 lg:m-4" />
            <Photo index={3} className="h-48 m-2 lg:m-4" />
            <Photo index={4} className="h-48 m-2 lg:m-4" />
            <Photo index={5} className="h-48 m-2 lg:m-4" />
            <Photo index={6} className="h-48 m-2 lg:m-4" />
            <Photo index={7} className="h-48 m-2 lg:m-4" />
            <Photo index={8} className="h-48 m-2 lg:m-4" />
            <Photo index={9} className="h-48 m-2 lg:m-4" />
          </Carrousel>
        </div>
        <div id="form" className="absolute -mt-40" />
        <div className="pb-16 px-2">
          <Title className="text-center">Check availability</Title>
          <Form />
        </div>
        <div className="flex justify-center items-center -mb-40">
          <Photo index={10} className="h-64 lg:h-96 m-2 lg:m-4" />
        </div>
        <div className="w-screen bg-blue py-12 pl-8 lg:pr-pl-8 text-white shadow pt-48">
          <div className="flex flex-col justify-center items-center max-w-screen-lg mx-auto">
            <Title white>La réservation de votre photobooth <br /> <div className="font-thin inline lg:ml-16">n’a jamais été aussi simple</div></Title>
            <div className="relative mt-10">
              <div className="absolute top-0 h-full w-full flex lg:justify-center"><div className="w-1 lg:w-2 bg-white rounded-full lg:-ml-1 shadow" /></div>
              {[
                { title: 'Vérifiez la disponibilité', description: `Grâce au formulaire en bas de page, vous pourrez vérifier 
                sans engagement la disponibilité de la borne Smiley Box pour la date de votre événement. Le photobooth sera alors réservé pour vous sans surcoût pour une 
                durée de trois heures. Si vous souhaitez transformer cette option en réservation formelle, il vous suffira de régler le montant de la location dans votre espace personnel.`, date: `Dès maintenant` },
                { title: 'Bloquez votre réservation', description: `Dès que vous aurez cliqué sur le bouton « Réserver », vous pourrez indiquer vos 
                coordonnées bancaires et régler ensuite le montant de la réservation sous 7 jours via
                l'un de nos moyens de paiement. Notez toutefois que plus vite vous réglerez pour 
                la réservation, plus vous serez certains d’avoir votre borne photo pour votre événement.`, date: `Dès maintenant` },
                { title: 'Personnalisez votre borne', description: `Sur votre espace client, vous pouvez choisir le design de votre impression photo parmi une large sélection de visuels mis à votre disposition.`, date: `Jusqu'à 7 jours avant votre événement` },
                { title: 'Se faire livrer son photobooth', description: `Sur votre espace client, indiquez à quel endroit la borne photo Smiley Box vous sera livrée. Vous recevrez votre borne photo 2 à 3 jours avant la date de votre événement afin d’avoir assez de temps pour effectuer le montage et les premiers tests.`, date: `Jusqu'à 7 jours avant votre événement` },
                { title: 'Récupérez et montez votre borne', description: `Le montage du photobooth Smiley Box est extrêmement facile et peut être réalisé en 
                quelques minutes. Nous vous enverrons des indications détaillées pour un montage et un démontage faciles. Vous trouverez également une vidéo explicative sur votre espace client.` },
                { title: 'Renvoyez nous la borne', description: `Le photobooth Smiley Box doit nous être retourné au premier jour ouvré suivant votre 
                événement. Vous n’avez pas à payer de coût supplémentaire pour cela : cette prestation est incluse. Vous trouverez plus d’informations à ce sujet sur votre espace client.`, date: `Quelques jours après votre événement` },
                { title: 'Galerie photo', description: `Dès que le photobooth arrive dans notre entrepôt, toutes les photos sont envoyées sur une galerie web privative. Vous recevrez dans les 7 jours après votre événement, un lien vous permettant d’accéder à toutes les photos prises. Vous pourrez partager cette galerie avec tous vos invités.`, date: `Dès la réception de votre borne` },
              ].map((s, index) => (
                <div key={s.title} className={`w-full lg:w-1/2 py-5 lg:my-4 pl-8 pr-2 lg:px-10 ${index % 2 ? 'lg:text-right' : 'lg:text-left lg:ml-1/2'}`}>
                  <div className="absolute bg-orange w-10 h-10 lg:w-12 lg:h-12 rounded-full flex justify-center items-center -ml-12 lg:-ml-6 mt-4 font-bold shadow lg:left-1/2">
                    {index + 1}
                  </div>
                  <div className="font-bold text-lg leading-tight">{s.title}</div>
                  <div className="text-sm opacity-75 -mt-1 mb-4">{s.date}</div>
                  <div className="text-sm lg:text-base leading-tight">{s.description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer showHelp={() => setShowHelp(true)} />
    </>
  )*/
}
